.register {
  &__title {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 10vh;
    margin-bottom: 0;
  }
  &__form {
  }
  &__progress-bar {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;

    span {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #6a6a6a;
      color: #fff;
      &.active {
        background-color: $tts-color;
      }
    }
    hr {
      width: 30%;
    }

    @media (max-width: 575px) {
      max-width: 80%;
    }
  }
}
