@-webkit-keyframes swing-top-fwd {
  0% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes swing-top-fwd {
  0% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

.swing-top-fwd {
  -webkit-animation: swing-top-fwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: swing-top-fwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
