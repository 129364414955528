.ts {
  &-card {
    margin: 0.5rem 0;
    background-color: #fff;
    padding: 0.5rem;
    

    &__padding {
      width: 9px;
      height: 25px;
      // background-color: $tts-color;
      margin-right: 0.4rem;
    }
    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
    &__title {
      font-size: 1rem;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 0px;
      text-transform: capitalize;
    }
    &__action {
      margin-left: auto;
      // font-size: 0.8rem;
      margin-right: 0.5rem;
    }
  }
}
