@import "slider";
@import "info";
@import "shop-info";
@import "breadcrumb";
@import "appbar";
@import "description";
@import "variant-select";
.product-details {
  background-color: #eee;
  min-height: 100vh;
}
