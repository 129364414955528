.user-page {
  &__hero {
    width: 100%;
    height: calc(125px + env(safe-area-inset-top));
    background-image: linear-gradient(145deg, rgb(49, 49, 65), rgb(31, 31, 50));
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__name {
    font-size: 1.2rem;
  }
}
