.login-page {
  padding-top: 15vh;
  &__title {
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 0;
  }
  &__slogan {
    text-align: center;
    color: $color-black-light;
  }
  &__form {
    width: 100%;
    padding: 0 1rem;
    .MuiFormControl-root {
      width: 100%;
    }
  }
  &__errors {
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.4rem 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #d8000c;
    border-radius: 7px;
    background-color: #ffbaba;
  }
}
