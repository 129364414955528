.messenger-page {
  background-color: #fff;
  min-height: 500px;
  text-align: center;
  padding: 2rem 0;
  &__title {
    font-size: 1.5rem;

    text-align: center;
    margin-bottom: 20px;
  }

  &__app-item {
    img {
      width: 150px;
      height: 43px;
    }
  }
}
