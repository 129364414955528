.ts {
  &-image {
    position: relative;
    &__sizer {
      display: block;
      padding-top: 100%;
    }
    &__img {
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      height: 0;
      max-height: 100%;
      max-width: 100%;
      min-height: 100%;
      min-width: 100%;
      width: 0;
      margin: auto;
    }
  }
}
