.appbar {
  &__margin {
    height: calc(3.6rem + env(safe-area-inset-bottom));
  }
}

.product-details {
  .appbar {
    &__button {
      background: #ffebe5;
      border: none;
      border-radius: 20px;
      color: #ff5000;
      font-size: 1rem;
      font-weight: 500;
      min-width: 100px;
      padding: 0.3rem 0.8rem 0.3rem;
      margin-left: 0.5rem;
      border: 1px solid #ff5000;
      &--primary {
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          from(#ff7300),
          to(#ff6000)
        );
        color: #fff;
        border: 1px solid #ff7300;
      }
      &--mall {
        background: var(--tts-mall-color);
        border: 1px solid var(--tts-mall-color);
      }
      &--send-inquiry {
        background: #fff;
        color: #ff5000;
        border: 1px solid #ff5000;
      }
    }

    &__message {
      font-size: 0.8rem;
      & .MuiSvgIcon-root {
        width: 0.5em;
        height: 0.5em;
        color: #3399ff;
      }
    }
  }
}
