.home {
  &__products-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.2rem;
    background-color: #f4f4f4;
    &-view-all {
      background-color: $color-gray-lightest;
      font-weight: bold;
    }

    &-cta {
      grid-column: 1 / span 2;
    }

    @media (min-width: 576px) {
      grid-template-columns: repeat(4, 1fr);
      &-cta {
        grid-column: 1 / span 4;
      }
    }
  }
}
