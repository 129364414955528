.product-details {
  .shop-info {
    &__header {
      display: flex;
      align-items: flex-start;
    }
    &__avatar {
      margin-right: 0.5rem;
      img {
        width: 60px;
        height: 60px;
        // object-fit: cover;
      }
      &--bussiness {
        width: 90px;
      }
      &--vip {
        width: 25px !important;
        object-fit: cover;
      }
    }
    &__name {
      display: flex;
      align-items: flex-start;
      img {
        width: 75px;
        margin-right: 0.3rem;
      }
      h2 {
        font-size: 1.2rem;
        margin-bottom: 0;
      }
    }
    &__statistics {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    &__statistic {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & > :first-child {
        font-size: 1.1rem;
        // color: $tts-color;
        font-weight: 500;
      }
      & > :last-child {
        font-size: 0.7rem;
        margin-top: 0.5rem;
        color: $color-black-lightest;
      }
    }

    &__follow-btn {
      // margin-left: 10px;
      margin-top: 10px;
      background-color: transparent;
      color: #f58220;
      font-size: 13px;
      border: 1px solid #f58220;
      border-radius: 100px;
      padding: 2px 15px 2px 8px;

      &.followed {
        background-color: #ff9a45;
        color: #fff;
        border-radius: 3px;
        padding: 0px 5px 0px 8px;
        border: none;
      }
    }
  }
}
