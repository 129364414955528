.user-page {
  &__section {
    h2 {
      font-size: 1rem;
      color: rgb(34, 34, 34);
      margin-bottom: 1.3rem;
    }

    &-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;
      grid-gap: 0.5rem;
    }
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        opacity: 0.5;
      }

      label {
        color: $color-black-lighter;
        font-size: 0.75rem;
        margin: 0;
        margin-top: 0.5rem;
      }
    }
  }
}
