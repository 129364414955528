.shopcard {
  padding: 1rem;
  background-color: #fff;
  &__header {
    display: flex;
    align-items: flex-start;
  }
  &__avatar {
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  &__shoptype {
    width: 60px;
    &--vip {
      width: 35px;
    }
  }
  &__shopname {
    font-size: 1rem;
  }
  &__statistics {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 1rem;
  }
}
