.footerbar {
  position: fixed;
  border-top: 1px solid #f4f4f4;
  bottom: -1px;
  left: 0;
  right: 0;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
  &__margin {
    height: 50px;
    width: 100%;
  }
}

@import "icon";
@import "nav-item";
