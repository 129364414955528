.product-details {
  &__breadcrumb-container {
    position: absolute;
    top: env(safe-area-inset-top);
    right: 0;
    left: 0;
    z-index: 135;

    &.sticky {
      // height: 50px;
      &:after {
        background: none;
      }
      position: fixed;
      top: 0;
      // height: calc(50px + env(safe-area-inset-top));
      padding-top: env(safe-area-inset-top);
      width: 100%;
      background-color: #fff;
      border-bottom: 1px solid #eee;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
    }

    &.sticky .product-details__breadcrumb-item {
      background-color: transparent;
      fill: rgb(102, 102, 102);
    }
  }
  &__breadcrumb {
    height: 50px;
    display: flex;
    align-items: center;
    fill: #fff;

    &-item {
      width: 35px;
      height: 35px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin-left: 10px;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &-item:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    &.sticky &-item {
      background-color: transparent;
    }

    .MuiIconButton-root {
      // padding: 5px;
    }
    .MuiSvgIcon-root {
      fill: inherit;
      // padding: 5px 7px;
      font-size: 1.5rem;
    }
  }

  & .breadcrumb {
    &__back {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='21' height='37' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.243 18.385l16.3 16.3-2.122 2.12-16.3-16.299L0 18.385 18.385 0l2.121 2.121L4.243 18.385z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E");
      background-position: 50%;
      background-size: 0.75rem;
      background-repeat: no-repeat;
      width: 3rem;
      height: 3.5rem;
    }
  }

  .more-menu-wrapper {
    // width: 50px;
    padding: 7px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    top: calc(50px + env(safe-area-inset-top));
    right: 10px;
    position: fixed;
    border-radius: 3px;
    display: none;

    &.visible {
      display: block;
    }
  }

  .more-menu-item {
    .MuiSvgIcon-root {
      fill: #fff;
      // padding: 5px 7px;
      font-size: 1.2rem;
    }
    padding: 10px 5px;
    font-size: 13px;
    display: flex;
    align-items: center;
    color: #fff;
    & i {
    }

    & label {
      margin-bottom: 0px;
      margin-left: 8px;
    }
  }
}
