// BASE VARIABLES

$tts-color: #f58220;
$tts-color-gradient: linear-gradient(
  90deg,
  rgb(255, 115, 0) 0%,
  rgb(255, 96, 0) 100%
);
$color-black: #212121;
$color-black-light: #404040;
$color-black-lighter: #686868;
$color-black-lightest: #929292;
$color-pearl: #007ace;
$color-gray-light: #637381;
$color-gray-lightest: rgba(0, 0, 0, 0.11);

:root {
  --tts-mall-color: #d50000;
}

.root,
#root,
#docs-root {
  --tts-mall-color: #d50000;
}
