.cart-page {
  &__emty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 1.4rem;
      color: rgba(0, 0, 0, 0.7);
    }
    p {
      color: rgba(0, 0, 0, 0.6);
    }
    .MuiSvgIcon-root {
      width: 9em;
      height: 9em;
      fill: rgba(0, 0, 0, 0.1);
    }
  }

  &__item {
    background-color: #fff;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &-header {
      h3 {
        font-size: 1.2rem;
      }
      margin-bottom: 0.5rem;
    }
  }

  &__variant {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    img {
      width: 50px;
      height: 50px;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &-price {
      font-size: 1rem;
      font-weight: bold;
      color: $tts-color;
    }

    &-quantity {
      width: 4rem;
      text-align: center;
      border: 0.0625rem solid #e0e0e0;
      border-radius: 0.25rem;
      &:focus {
        outline: none;
      }
    }
  }

  &__summary {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #fbc09e;
    background: #fff1dc;
    padding: 0.3rem 0.9375rem;
    padding-bottom: calc(0.3rem + env(safe-area-inset-bottom));
    -moz-box-shadow: 0 -2px 1px 0px rgba(218, 218, 218, 0.7);
    -webkit-box-shadow: 0 -2px 1px 0px rgba(218, 218, 218, 0.7);
    box-shadow: 0 -2px 1px 0px rgba(218, 218, 218, 0.7);
    p {
      margin-bottom: 0;
    }
  }
  &__checkout {
    padding: 0.3rem 0.5rem;
    border-radius: 5px;
    border: 0;
    background-image: -webkit-linear-gradient(top, #ff9726, #ff8416);
    color: #fff !important;
    text-transform: initial !important  ;
    box-shadow: none !important;
    &:disabled {
      background-image: none;
      background-color: graylight !important;
    }
  }
}
