.variant-select {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // top: 18%;
  min-height: 70vh;
  animation: riseTop 0.2s;
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);

  &__mall {
    background: var(--tts-mall-color) !important;
  }

  &--single {
    // single variant modal
    // top: 40%;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    height: 80px;
  }

  &__thumbnail {
    width: 80px;
    height: 80px;
    border: 0.125rem solid #fff;
    margin-top: -25px;
    margin-left: 0.7rem;
    background: white;
  }

  &__prices {
    width: 70%;
    display: flex;
    // justify-content: space-between;
  }

  &__price {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    font-size: 0.75rem;
    &--active {
      color: $tts-color;
    }
    span:first-child {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  &__close {
    position: absolute;
    top: -30px;
    right: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  &__main {
    margin: 16px 0px;
  }

  &__submit {
    background-color: none;
    border: none;
    padding: 0.5rem 0;
    color: #f58220;
    background-color: #fff0e6;
    // width: 70%;
    flex: 1;
    border-radius: 1.8rem;
    &:disabled {
      opacity: 0.7;
    }
    &.primary {
      color: #fff;
      background-image: $tts-color-gradient;
    }
  }

  @keyframes riseTop {
    0% {
      top: 90%;
    }
    100% {
      // top: 18%;
      top: 30%;
    }
  }
}
