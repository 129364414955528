.ts {
  &__checkbox {
    width: 1.25rem;
    height: 1.25rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("/static/images/checkbox.png") 0 0 no-repeat;
    background-size: contain;
    border: 0 none;

    &:checked {
      background-image: url("/static/images/checkbox-checked.png");
    }
    &:focus {
      outline: none;
    }
  }
}
