.shop-details {
  &__cover {
    width: 100%;
    height: 20vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &__avatar {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 3px;
  }
  &__name {
    font-size: 1.3rem;
    color: $color-black-light;
  }
  &__badge {
    &--bussiness {
      width: 70px;
    }
    &--vip {
      width: 35px !important;
    }
  }
  &__interactive {
    display: flex;
    justify-content: space-around;
    .MuiButton-contained {
      box-shadow: none;
    }
  }
  &__description-post {
    max-width: 100%;
    img {
      max-width: 100% !important;
      height: auto !important;
      padding: 12px 8px;
    }
  }
}

@import "shop-info-list";
