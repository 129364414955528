.page-breadcrumb {
  padding-top: env(safe-area-inset-top);
  height: calc(50px + env(safe-area-inset-top));
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgb(248, 244, 244);
  z-index: 150;
  a {
    display: inline-flex;
  }
  &__back {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='21' height='37' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.243 18.385l16.3 16.3-2.122 2.12-16.3-16.299L0 18.385 18.385 0l2.121 2.121L4.243 18.385z' fill='rgba(0, 0, 0, 0.54)' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-position: 50%;
    background-size: 0.75rem;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3.5rem;
  }
  &__title {
    font-size: 1.2rem;
    margin-bottom: 0;
    flex: 1;
    text-align: center;
  }
  .MuiIconButton-root {
    padding: 0;
    &:not(:last-child) {
      margin-right: 0.7rem;
    }
  }
  .MuiSvgIcon-root {
    width: 1.3em;
    height: 1.3em;
  }

  &__gap {
    height: calc(50px + env(safe-area-inset-top));
  }
}
