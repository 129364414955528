.product-details {
  &__title {
    font-size: 1rem;
  }

  &__single-price {
    font-size: 1.4rem;
    font-weight: bold;

    &--discount {
      font-size: 1rem;
    }
  }

  &__saved-percent {
    margin-left: 1.2rem;
    background-color: #f58220;
    border-radius: 5px;
    padding: 0.15rem 0.5rem;
    color: #fff;
    font-weight: 600;
  }
  &__multiple-prices {
    display: flex;
    align-items: center;
  }

  &__multiple-price {
    flex: 1;
    &--discount {
      font-size: 0.8rem;
      text-decoration: line-through;
    }
  }

  &__alert {
    font-size: 0.9rem;
    display: inline-block;
    padding: 5px 7px;
    color: #fff;
    background-color: #f44336;
    margin: 7px 0px 14px 0px;
    border-radius: 4px;
  }
}
