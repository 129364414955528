.promoted-slider {
  display: block;
  overflow-x: hidden;
  width: 100%;
  // background-color: #d9d9d9;
  overflow-y: hidden;

  &__item {
    img {
      border-radius: 5px;
      width: 100%;
      object-fit: contain;
      // height: 150px;
    }
  }

  @media (min-width: 768px) {
    height: 250px;
    &__item img {
      height: 250px;
    }
  }
}
