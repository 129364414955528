.order-list {
  background-color: #fff;
  padding-top: 1rem;

  &__loading,
  &__empty {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.order-item {
  padding: 0.7rem 0.3rem;
}

.order-chip {
  font-size: 0.75rem !important;
  margin-bottom: 0.5rem;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
  & .MuiChip-iconSmall {
    width: 10px !important;
    height: 10px !important;
  }

  & .MuiChip-icon {
    color: rgb(182, 182, 182);
  }

  &__pending {
    background-color: #ffc58b !important;
    color: #594430 !important;
    & .MuiChip-icon {
      color: #594430;
    }
  }
  &__success {
    background-color: #bbe5b3 !important;
    color: #414f3e !important;
    & .MuiChip-icon {
      color: #678360;
    }
  }
  &__cancelled {
    background-color: #dc3545 !important;
    color: #fff !important;
    & .MuiChip-icon {
      color: #fff;
    }
  }
}
