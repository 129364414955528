.category-page {
  display: flex;
  &__tabs {
    width: 30%;
    max-height: calc(100vh - 60px);
    .MuiTab-root {
      padding: 0;
      &.Mui-selected {
        background-color: #eee;
      }
    }
  }
  &__tab {
    padding: 0.3rem 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0.2rem;
    text-transform: none;
    font-size: 0.75rem;
    img {
      width: 25px;
      margin-bottom: 0.3rem;
    }
  }
  &__content {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 0.5rem 0.5rem;
    height: calc(100vh - 60px);
    overflow: auto;
  }
  .MuiTabs-root {
    background-color: rgba(0, 0, 0, 0.02);
  }
  .MuiTabs-indicator {
    left: 0;
    background-color: $tts-color;
  }
}
