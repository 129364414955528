.product-details {
  &__slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: contain;
    }

    &-sizer {
      padding-top: 100%;
    }
    &-index {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 13px;
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      padding: 3px 7px;
    }
  }
}
