.tts-mobile-web {
  width: 100%;
  min-height: 100vh;
}

body {
  background-color: #eee;

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.02);
  }
  a:hover {
    text-decoration: none;
    background-color: rgba(165, 165, 165, 0.01);
  }
  a:focus {
    background-color: transparent;
  }
}

* {
  touch-action: manipulation;
  word-break: break-word;
}

button:focus,
input:focus {
  outline: none;
}
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}

.text-orange {
  color: $tts-color !important;
}

.bg-tts {
  background-color: $tts-color !important;
}

.border-tts {
  border-color: $tts-color !important;
}

.bg-gray {
  background-color: rgba(0, 0, 0, 0.11);
}

.bg-gray-lighter {
  background-color: rgba(0, 0, 0, 0.05);
}

.text-gray {
  color: $color-black-lighter;
}

.text-initial {
  text-transform: initial !important;
}

.MuiCircularProgress-colorPrimary {
  color: $tts-color !important;
}

.hover-darker {
  &:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.hover-lighter {
  &:hover {
    opacity: 0.5;
  }
}

.font-90 {
  font-size: 90%;
}

.font-75 {
  font-size: 75%;
}
.rounded-100 {
  border-radius: 100px;
}

.ios-app-safe-top-area {
  padding-top: env(safe-area-inset-top);
  display: block;
}
.ios-app-safe-bottom-area {
  padding-top: env(safe-area-inset-bottom);
  display: block;
}

.show-more-text a {
  color: blue;
}

.ril__toolbar {
  top: env(safe-area-inset-top);
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
