.ts {
  &-classified {
    min-width: 0;
    padding: 0px 5px 5px 5px;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      color: #000;
      margin-bottom: 0.2rem;
      font-size: 0.875rem;
      // font-weight: 600;
    }

    &__created-at {
      color: #565656;
      text-align: right;
      margin-top: auto;
      font-size: 0.9rem;
    }

    &__image-container {
      flex-basis: 20%;
      flex-shrink: 0;
      padding-top: 9px;
      padding-right: 10px;
    }

    &__badge {
      // background-color: #ececec;
      color: #4e4e4e;
      border-radius: 100px;
      font-size: 12px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
