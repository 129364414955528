@import "hero";
@import "section";

.user-page {
  &__avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  &__login-action {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid #fff;
    background-color: $tts-color;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 100px;
  }
}
