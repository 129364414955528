.shop-info {
  &-list {
    padding: 0;
    list-style: none;
    &__item {
      display: flex;
      align-items: flex-start;
      font-size: 0.85rem;
      .name {
        flex-basis: 40%;
        flex-shrink: 0;
        color: rgb(120, 120, 120);
      }
      .value {
        color: rgb(36, 36, 36);
        padding-left: 8px;
        flex: 2 0 0%;
      }
      &:not(:last-child) {
        margin-bottom: 0.7rem;
      }
    }
  }
}
