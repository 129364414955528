.ts {
  &-tag {
    background-color: #fff;
    color: $color-black;
    padding: 0.2rem 0.7rem;
    border-radius: 30px;
    margin: 0.3rem;
    display: inline-block;
    border: 1px solid rgba(0,0,0,.1)
  }
}
