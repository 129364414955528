.home__featured-category {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  &__item {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.2rem;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 0.4rem;
      }
    }
    svg {
      width: 45px;
      height: 45px;
      stroke: #fff;
    }

    &:first-child {
      grid-row: 1 / span 2;
      a {
        display: flex;
        flex-direction: column;
        svg {
          margin-right: 0;
          width: 35px;
          height: 35px;
          margin-bottom: 0.4rem;
        }
        span {
          text-align: center;
        }
      }
    }

    &:not(:first-child) {
      svg {
        fill: #fff;
      }
    }
    a {
      color: #fff;
    }
  }
}
