.page-header {
  width: 100%;
  padding-top: env(safe-area-inset-top);
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 1001;
  height: calc(60px + env(safe-area-inset-top));

  &__intro {
    display: block;
    padding: 0.5rem 0.5rem;
  }
  &__logo {
    margin-bottom: 0.5rem;
    width: 115px;
    height: 26px;
  }

  &__searching {
    height: 60px;
    background-color: #fff;
    padding: 0rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    // &--fixed {
    //   height: calc(60px + env(safe-area-inset-top));
    //   padding-top: env(safe-area-inset-top);
    //   position: fixed;
    //   top: 0;
    //   width: 100%;
    //   box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
    //   border-bottom: 1px solid rgb(248, 244, 244);
    // }
  }
  &__search-field {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 0.4rem 0.5rem;
    flex: 0.9;
    img {
      width: 1rem;
      height: 1rem;
    }
    span {
      margin-left: 0.5rem;
      color: #93959a;
    }
  }

  &__notifications {
    // width: 30%;
    // display: flex;
    // justify-content: space-evenly;
    // .MuiIconButton-root {
    //   padding: 0;
    // }
    .MuiSvgIcon-root {
      fill: $tts-color;
      width: 1.2em;
      height: 1.2em;
    }
    .MuiBadge-anchorOriginTopRightRectangle {
      font-size: 0.6rem;
      top: 4px !important;
      // right: 2px !important;
    }
  }
  &__myaccount {
    .MuiSvgIcon-root {
      fill: $tts-color;
      width: 1.2em;
      height: 1.2em;
    }
  }
}
