.ts {
  &-product-card {
    min-width: 0;
    padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 3px;

    &__thumbnail {
      border-radius: 3px;
    }
    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-top: 0.4rem;
      margin-bottom: 0.2rem;
      font-size: 0.9rem;
      font-weight: 400;
    }
    &__price {
      font-weight: bold;
      color: darken($tts-color, 5%);
      font-size: 0.9rem;
    }
    &__discount {
      border-radius: 0px 0px 0px 26px;
      background-color: rgb(255, 66, 78);
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 13px;
      padding: 1px 2px 3px 8px;
      /* padding-left: 7px; */
      font-weight: 600;
      line-height: 20px;
    }
    &__min-quantity {
      font-size: 0.8rem;
      color: $color-black-lighter;
    }

    &__sponsored {
      position: absolute;
      bottom: 1px;
      right: 1px;
      font-size: 10px;
      background-color: #f1c40f;
      padding: 1px 5px;
      color: #fff;
      border-radius: 2px;
    }

    &__cta {
      grid-column: 1 / span 2;
      @media (min-width: 576px) {
        grid-column: 1 / span 4;
      }
    }

    &__better-prices {
      background: #fff4ed;
      color: #f60;
      height: 18px;
      line-height: 18px;
      padding: 0 4px;
      font-size: 12px;
      border-radius: 2px;
      white-space: nowrap;
      width: fit-content;
    }
  }
}
