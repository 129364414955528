.footerbar {
  &__nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px 0px;
    label {
      margin-bottom: 2px;
      font-size: 0.7rem;
      color: $color-black-light;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--active {
      svg {
        fill: $tts-color;
      }
      label {
        color: $tts-color;
      }
    }
  }

  &__icon {
    position: relative;
  }

  &__new {
    position: absolute;
    top: -1px;
    right: -50%;
    font-size: 10px;
    line-height: 8px;
    color: #fff;
    background-color: red;
    padding: 3px;
    border-radius: 50px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    text-align: center;
  }

  &__badge {
    position: absolute;
    top: -1px;
    right: -2px;
    font-size: 12px;
    line-height: 8px;
    color: #fff;
    background-color: red;
    padding: 3px;
    border-radius: 50px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    text-align: center;
  }
}
