.search-modal {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-top: env(safe-area-inset-top);

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0rem;
  }

  &__input {
    width: 70%;
    padding: 0.35rem 0.5rem;
    background-color: #f5f5f5;
    border-radius: 20px;
    margin-right: 1rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0,0,0,.02);
    img {
      width: 1rem;
      height: 1rem;
      margin-bottom: 0.1rem;
      margin-right: 1rem;
    }
    input {
      flex: 1;
      border: none;
      outline: none;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }

  &__tabs {
    -webkit-box-shadow: 0px 5px 7px -4px rgba(148, 148, 148, 0.65);
    -moz-box-shadow: 0px 5px 7px -4px rgba(148, 148, 148, 0.65);
    box-shadow: 0px 5px 7px -4px rgba(148, 148, 148, 0.65);
    .MuiTabs-indicator {
      background-color: $tts-color;
    }
    .MuiTab-textColorInherit.Mui-selected {
      color: $tts-color;
    }
  }
}

@import "./product-panel";
@import "./tab-panel";
